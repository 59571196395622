.footer {
  background: $primary;
  padding-top: 70px;
  padding-bottom: 70px;
  color: $white;

  .newsletter {
    h5 {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .footer-input {
    background-color: transparent;
    margin-left: 15px;
    height: 50px;

    input {
      outline: none;
      padding: 15px 0;
      margin: 0;
      box-shadow: none;
      border: none;
      color: white;
      border-bottom: 3px solid $white;
      background: transparent !important;
      background-image: none !important;

      &:focus {
        outline: none;
        box-shadow: none;
        border-bottom: 3px solid $yellow;
        background-image: none !important;
      }

      &::placeholder {
        color: white;
        opacity: 0.7;
      }
    }
  }

  button {
    font-family: 'Monument Extended', sans-serif !important;
    font-weight: 800 !important;
    font-size: 30px;
    color: white;

    &:hover {
      color: $yellow
    }
  }

  a {
    color: $white;
  }
}

.custom-footer {
  border-top: 1px solid #373535;
}

.sub-form {
  display: flex;

  .sub-button {
    margin-left: 5px;
  }
}