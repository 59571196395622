$primary: #0967D2;
$blackPerl: #1c1c4b;
$fadedGrey: #F1F2F4;
$white: #FFFFFF;
$yellow: #f6a607;
$grey: #F0F2F4;
$dark: #040415;


$sidedisplay-transition: margin-right .35s ease-in-out, visibility .35s ease-in-out !default;

// CUSTOM THEME
$theme-colors: (
  "primary": $primary,
  "yellow": $yellow,
  "grey": $grey,
  "dark": $dark
);

// Spacer
$spacer: 1rem !default;

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4.5),
  7: ($spacer * 6)
) !default;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;
