* {
  // font-family: Proxima Nova;
  // font-family: GT Walsheim Pro;
  font-family: 'Clash Display', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Monument Extended', sans-serif;
  font-weight: 400;
}

h1, h2 {
  font-weight: 800;
}

@import "variables";

@import "partials/navbar";
@import "partials/landing";
@import "partials/footer";

// Bootstrap
@import "bootstrap/scss/bootstrap";
