.landing {
  margin-top: 90px;

  .bottom-divided {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    border-top: 10px solid $blackPerl;

    @media (min-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
      flex-direction: row;
    }

    &>* {
      padding: 50px 20px;

      @media (min-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
        padding: 50px;
        max-width: 50%;
      }

    }

    &>*:not(:last-child) {
      // background-color: $yellow;
      z-index: 2 !important;
    }
  }

  .how-it-works {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 7rem 0;
    padding: 2rem 1rem;

    @media (min-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
      flex-direction: row;
      padding: 2rem 7rem;
    }

    div {
      border: 3.5px solid $blackPerl;
      border-radius: 12px;
      position: relative;

      &::after {
        display: block;
        content: "";
        height: 20px;
        width: 100%;
        position: absolute;
        bottom: 0;
        border-radius: 0px 0px 9.5px 9.5px;
      }


      &:first-of-type {
        &::after {
          background-color: $primary;
        }
      }
      &:nth-of-type(2) {
        &::after {
          background-color: rgb(134, 241, 134);
        }
      }
      &:nth-of-type(3) {
        &::after {
          background: rgb(121, 6, 121);
        }
      }

      @media (min-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
        max-width: 30%;
      }

      p {
        padding: 1.5rem;
        backdrop-filter: blur(3px);
        border-radius: 12px;

        @media (min-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
          padding: 1.5rem;
        }

        &:first-of-type {
          border-bottom: 3.5px solid $blackPerl;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
  }

  .question-card {
    border: 3px solid $blackPerl;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 20px;

    summary {
      list-style: none;
      display: flex;

      &::-webkit-details-marker {
        display: none !important;
      }
    }

    .question-answer {
      background-color: $yellow;
      margin: 10px -15px -16px;
      padding: 10px 15px 20px;
      border-top: 2px solid $blackPerl;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      span {
        margin: 0;
        color: $blackPerl;
      }
    }

    &[open] {
      summary~* {
        animation: sweep 0.5s ease-in-out;
      }

      .toggler-arrow {
        transform: rotate(180deg) !important;
      }
    }

    @keyframes sweep {
      0% {
        opacity: 0;
        margin-top: -5px;
      }

      100% {
        opacity: 1;
        margin-top: 0px;
      }
    }
  }
}

#hastle {
  background: url("../../images/backgrounds/hastle-bg.jpg");
  background-blend-mode: saturation;
  background-repeat: no-repeat;

  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'sm') - .02px}) {
    background: none;
  }

  h1 {
    font-weight: 800 !important;
    color: $yellow;
    font-size: 6rem;

    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'sm') - .02px}) {
      font-size: 3rem;
    }
  }

  h3 {
    font-size: 2rem;

    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'sm') - .02px}) {
      font-size: 1rem;
    }
  }
}